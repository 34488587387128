import React, {FunctionComponent} from 'react';

interface OwnProps {
  color?: string;
}

const AppStoreIcon: FunctionComponent<OwnProps> = ({
  color = '#000',
}: OwnProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 77 16">
      <path
        fill={color}
        fillRule="evenodd"
        d="M41.2 11.5c-1.6 0-2.9-.8-3-2.1h-2c.2 2.3 2 3.7 4.9 3.7 3 0 5-1.4 5-3.8 0-1.9-1.2-3-3.8-3.6L41 5.4c-1.6-.4-2.3-1-2.3-1.8 0-1.2 1-2 2.6-2 1.5 0 2.6.8 2.7 2.1h1.9c0-2.2-1.9-3.7-4.6-3.7-2.7 0-4.6 1.5-4.6 3.7 0 1.8 1 2.9 3.4 3.4l1.7.4c1.6.4 2.3 1 2.3 2 0 1.2-1.2 2-2.8 2Zm-38-2h4.9l1.1 3.4h2.1L6.7.2H4.6L0 13h2l1.2-3.4Zm4.4-1.6H3.7l2-5.6L7.6 8Zm9.8 5.1c2.3 0 3.8-1.8 3.8-4.7 0-2.9-1.5-4.7-3.9-4.7a3.3 3.3 0 0 0-3 1.6V3.7h-1.8V16h1.9v-4.6a3.1 3.1 0 0 0 3 1.6Zm-.6-7.8c1.5 0 2.5 1.2 2.5 3.1 0 1.9-1 3.1-2.5 3.1-1.4 0-2.4-1.2-2.4-3.1 0-1.9 1-3.1 2.4-3.1Zm14.6 3.1c0 2.9-1.5 4.7-3.8 4.7a3.1 3.1 0 0 1-3-1.6V16h-1.9V3.7h1.9v1.5a3.3 3.3 0 0 1 3-1.6c2.3 0 3.8 1.8 3.8 4.7Zm-2 0c0-1.9-.9-3.1-2.4-3.1-1.4 0-2.4 1.2-2.4 3.1 0 1.9 1 3.1 2.4 3.1 1.5 0 2.5-1.2 2.5-3.1Zm20.7-6.8v2.2H52v1.5H50v5c0 .9.4 1.2 1.1 1.2h.7v1.5a5.2 5.2 0 0 1-1 0c-2 0-2.7-.6-2.7-2.4V5.2h-1.4V3.7h1.4V1.5h2Zm7.2 2c-2.7 0-4.4 1.9-4.4 4.8 0 3 1.7 4.7 4.4 4.7s4.4-1.8 4.4-4.7c0-3-1.7-4.8-4.4-4.8Zm0 1.6c1.5 0 2.5 1.2 2.5 3.2s-1 3.2-2.5 3.2-2.5-1.2-2.5-3.2 1-3.2 2.5-3.2Zm6-1.4H65v1.5a2.2 2.2 0 0 1 2.3-1.6h.6v1.8a2.7 2.7 0 0 0-.8-.1 2 2 0 0 0-2 2.1V13h-2V3.7Zm9.5 9.3c2 0 3.7-1.1 4-2.8H75a2.1 2.1 0 0 1-2.2 1.3A2.4 2.4 0 0 1 70.3 9v-.1H77V8c0-2.8-1.6-4.6-4.2-4.6s-4.3 2-4.3 4.8c0 3 1.7 4.7 4.4 4.7ZM75 7.4h-4.7a2.4 2.4 0 0 1 2.4-2.3A2.2 2.2 0 0 1 75 7.4Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default AppStoreIcon;
