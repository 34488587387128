import {FunctionComponent} from 'react';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import ru from 'date-fns/locale/ru';

interface OwnProps {
  format?: string;
  children: string | Date;
}

export function formatAnything(value: string | Date, format: string): string {
  try {
    return formatDate(value instanceof Date ? value : parseISO(value), format, {
      locale: ru,
    });
  } catch (e) {
    return '';
  }
}

const PrettyDate: FunctionComponent<OwnProps> = ({
  children,
  format = 'd MMMM yyyy',
}) => (formatAnything(children, format) as any) as JSX.Element;

export default PrettyDate;
