import React, {FunctionComponent, useCallback, useState} from 'react';
import cx from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import Button from '@pollex/components/button';
import Carousel from '@pollex/components/carousel';
import Modal from '@pollex/components/modal';
import {useAnalytics} from 'client/contexts/Analytics';
import ArrowBold from '@pollex/components/icon/components/ArrowBold';

import massMedia1 from './img/mass_media_1.png';
import massMedia2 from './img/mass_media_2.png';
import massMedia3 from './img/mass_media_3.jpg';
import massMedia4 from './img/mass_media_4.jpg';
import massMedia5 from './img/mass_media_5.png';

import './UsInMassMedia.scss';

const EMBED_BY_LINK = {
  'https://www.1tv.ru/-/sfejmi': 'https://www.1tv.ru/embed/231816:12',
  'https://www.1tv.ru/-/xhcbnw': 'https://www.1tv.ru/embed/232042:12',
  'https://www.1tv.ru/-/langfr': 'https://www.1tv.ru/embed/240569:12',
  'https://www.1tv.ru/-/hpnnou': 'https://www.1tv.ru/embed/240713:12',
  'https://www.1tv.ru/-/gpfuej':
    'https://hands.ru/media/6f/b5/6fb597ff00a4229d989640280e4a7925.mov',
};

interface MassMediaArticle {
  link: string;
  imageSrc: string;
  imageAlt: string;
  title: JSX.Element;
  text: JSX.Element;
  duration: string;
}

const ARTICLES: MassMediaArticle[] = [
  {
    link: 'https://www.1tv.ru/-/gpfuej',
    imageSrc: massMedia5,
    imageAlt: 'Руки в передаче про подработку летом',
    title: <>Подработка летом: платформа поможет</>,
    text: <>Доброе утро. Фрагмет выпуска от 24.06.2024</>,
    duration: '3.02',
  },
  {
    link: 'https://www.1tv.ru/-/hpnnou',
    imageSrc: massMedia4,
    imageAlt: 'Руки в передаче про брак мастеров',
    title: <>Если мастер оказался &laquo;ломастер&raquo;</>,
    text: <>Доброе утро. Фрагмент выпуска от&nbsp;23.11.2023</>,
    duration: '2:52',
  },
  {
    link: 'https://www.1tv.ru/-/langfr',
    imageSrc: massMedia3,
    imageAlt: 'Руки в передаче про мастеров на все руки',
    title: <>Где найти мастера на&nbsp;все руки?</>,
    text: <>Доброе утро. Фрагмент выпуска от&nbsp;22.11.2023</>,
    duration: '2:57',
  },
  {
    link: 'https://www.1tv.ru/-/xhcbnw',
    imageSrc: massMedia2,
    imageAlt: 'Руки в передаче про честных мастеров',
    title: <>Честный мастер: а&nbsp;такое бывает?</>,
    text: <>Доброе утро. Фрагмент выпуска от&nbsp;09.06.2023</>,
    duration: '2:55',
  },
  {
    link: 'https://www.1tv.ru/-/sfejmi',
    imageSrc: massMedia1,
    imageAlt: 'Руки в передаче про платформенную занятость',
    title: <>Платформенная занятость&nbsp;&mdash; это что?</>,
    text: <>Доброе утро. Фрагмент выпуска от&nbsp;05.06.2023</>,
    duration: '2:19',
  },
];

const UsInMassMedia: FunctionComponent = () => {
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);
  const {handleEvent} = useAnalytics();
  const [carouselRef, carouselAPI] = useEmblaCarousel({
    align: 'center',
    containScroll: 'trimSnaps',
    skipSnaps: false,
    inViewThreshold: 0.6,
  });

  const handleClick = useCallback(e => {
    if (EMBED_BY_LINK[e.currentTarget.href]) {
      e.preventDefault();
      handleEvent('us_in_mass_media_click', {})();
      setCurrentVideo(EMBED_BY_LINK[e.currentTarget.href]);
    }
  }, []);

  return (
    <div className="us-in-mass-media">
      <h3 className="us-in-mass-media__heading">Руки в СМИ</h3>

      <Carousel
        carouselRef={carouselRef}
        carouselAPI={carouselAPI}
        leftNav={({onClick, enabled}) => (
          <Button
            design="plain"
            onClick={onClick}
            onClickCapture={handleEvent('carousel_nav_click', {
              component: 'us_in_mass_media_block',
            })}
            extraClassName={cx(
              'us-in-mass-media__navigation us-in-mass-media__navigation_left',
              {
                'us-in-mass-media__navigation_disabled': !enabled,
              },
            )}
          >
            <ArrowBold width="10" height="15" color="var(--ruki-white)" />
          </Button>
        )}
        rightNav={({onClick, enabled}) => (
          <Button
            design="plain"
            onClick={onClick}
            onClickCapture={handleEvent('carousel_nav_click', {
              component: 'us_in_mass_media_block',
            })}
            extraClassName={cx(
              'us-in-mass-media__navigation us-in-mass-media__navigation_right',
              {
                'us-in-mass-media__navigation_disabled': !enabled,
              },
            )}
          >
            <ArrowBold width="10" height="15" color="var(--ruki-white)" />
          </Button>
        )}
        bullets={() => null}
      >
        {ARTICLES.map(
          ({link, imageSrc, imageAlt, title, text, duration}, i) => (
            <a
              className="us-in-mass-media__article"
              href={link}
              onClick={handleClick}
              rel="nofollow noreferrer"
              key={link}
              data-testid={i === 0 ? 'us-in-mass-media-link' : ''}
              target="_blank"
            >
              <div className="us-in-mass-media__image-wrapper">
                <img
                  className="us-in-mass-media__image"
                  src={imageSrc}
                  alt={imageAlt}
                  loading="lazy"
                />
                <span className="us-in-mass-media__duration">{duration}</span>
              </div>
              <b className="us-in-mass-media__article-heading">{title}</b>
              <div className="us-in-mass-media__content">
                <p className="us-in-mass-media__channel">Первый канал</p>
                <span>{text}</span>
              </div>
            </a>
          ),
        )}
      </Carousel>

      {currentVideo && (
        <Modal onClose={() => setCurrentVideo(null)} size="photo">
          <div className="us-in-mass-media__video-container">
            {currentVideo.includes('media') ? (
              /* eslint-disable jsx-a11y/media-has-caption */
              <video className="us-in-mass-media__video" controls>
                <source src={currentVideo} />
              </video>
            ) : (
              <iframe
                title="Репортаж о Руках"
                src={currentVideo}
                allow="encrypted-media"
                allowFullScreen
                className="us-in-mass-media__frame"
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default UsInMassMedia;
