import React, {FunctionComponent} from 'react';
import Link from '@pollex/components/link';
import StaticLayout from 'client/components/Layouts/StaticLayout';
import {useQuery} from '@apollo/client';
import handsRuGetDataForMainPage, {
  HandsRuGetDataForMainPageData,
  HandsRuGetDataForMainPageVariables,
} from 'query-wrappers/HandsRuGetDataForMainPage';
import CircularProgress from '@pollex/components/circular-progress';
import {useAnalytics} from 'client/contexts/Analytics';
import Star from '@pollex/components/icon/components/Star';
import plural from '@pollex/utils/plural';
import format from '@pollex/utils/format-number';
import useAnalyticsEventOnView from 'client/hooks/useAnalyticEventOnView';
import {ExternalReviewSource} from 'client/types/external-review';
import ExternalReviewIcon from '../ExternalReviewIcon';

import './DryExternalReviews.scss';

const SOURCE_TO_NAME: Record<ExternalReviewSource, string> = {
  APP_STORE: 'AppStore',
  GOOGLE_PLAY: 'Google',
  TBANK: 'Т-Банк',
  TWO_GIS: '2GIS',
  YANDEX_MAPS: 'Яндекс',
};

const DryReviews: FunctionComponent = () => {
  const analytics = useAnalytics();
  const {data, loading} = useQuery<
    HandsRuGetDataForMainPageData,
    HandsRuGetDataForMainPageVariables
  >(handsRuGetDataForMainPage, {variables: {bannerKind: 'WEB'}});
  const ref = useAnalyticsEventOnView('dry_external_reviews_in_view', {});

  if (loading) {
    return <CircularProgress color="var(--ruki-grey)" />;
  }

  return (
    <StaticLayout.Widget
      padding="none"
      extraClassName="dry-external-reviews"
      innerRef={ref}
    >
      <StaticLayout.Block
        padding="vertical"
        extraClassName="dry-external-reviews__container"
      >
        <StaticLayout.Block extraClassName="dry-external-reviews__heading">
          <StaticLayout.Title extraClassName="dry-external-reviews__title">
            <span>
              {format(data?.handsCommonInfo?.numClients || 0)} клиентов
            </span>
            <span>уже воспользовались Сервисом Руки</span>
          </StaticLayout.Title>
        </StaticLayout.Block>

        <StaticLayout.Block
          padding="none"
          extraClassName="dry-external-reviews__list"
        >
          {data?.handsCommonInfo?.externalReviewCumulativeStat.map(
            externalServiceStat => (
              <Link
                href={externalServiceStat.link}
                external
                target="_blank"
                key={externalServiceStat.source}
                onClickCapture={analytics.handleEvent(
                  'dry_external_review_click',
                  {
                    source: externalServiceStat.source,
                  },
                )}
                className="dry-external-reviews__item"
              >
                <StaticLayout.Block
                  padding="none"
                  extraClassName="dry-external-reviews__item-icon"
                >
                  <ExternalReviewIcon
                    source={externalServiceStat.source}
                    variant="LOGO"
                  />
                </StaticLayout.Block>

                <StaticLayout.Block
                  padding="none"
                  extraClassName="dry-external-reviews__item-score"
                >
                  <div className="dry-external-reviews__item-score_star">★</div>
                  <div>
                    {(() => {
                      const value = format(
                        externalServiceStat.avgRating,
                        1,
                        '.',
                      );

                      if (value.includes('.')) {
                        return value;
                      }

                      return `${value}.0`;
                    })()}
                  </div>
                </StaticLayout.Block>

                <StaticLayout.Block
                  padding="none"
                  extraClassName="dry-external-reviews__item-info"
                >
                  <StaticLayout.Block
                    padding="none"
                    extraClassName="dry-external-reviews__item-info_top"
                  >
                    {externalServiceStat.numReviews}&nbsp;
                    {plural(
                      externalServiceStat.numReviews,
                      'оценка',
                      'оценки',
                      'оценок',
                    )}
                  </StaticLayout.Block>

                  <StaticLayout.Block
                    padding="none"
                    extraClassName="dry-external-reviews__item-info_bottom"
                  >
                    {SOURCE_TO_NAME[externalServiceStat.source]}
                  </StaticLayout.Block>
                </StaticLayout.Block>
              </Link>
            ),
          )}
        </StaticLayout.Block>

        <Star
          width="400px"
          height="400px"
          viewBox="3 0 18 19"
          extraClassName="dry-external-reviews__container_background"
          color="var(--placeholder-shimmer)"
        />
      </StaticLayout.Block>
    </StaticLayout.Widget>
  );
};

export default DryReviews;
