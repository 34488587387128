import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import StaticLayout from 'client/components/Layouts/StaticLayout';
import Geo from '@pollex/components/icon/components/Geo';
import Rouble from '@pollex/components/icon/components/Rouble';
import Star from '@pollex/components/icon/components/Star';

import './HowWeWork.scss';

interface OwnProps {
  isBackoffice?: boolean;
}

const HowWeWork: FunctionComponent<OwnProps> = ({isBackoffice}) => {
  return (
    <StaticLayout.Widget grid={false} padding="bottom">
      <StaticLayout.Lead
        number={isBackoffice ? undefined : 1}
        component="li"
        extraClassName={cx('how-we-work__step', {
          'how-we-work__step_backoffice': isBackoffice,
        })}
      >
        {isBackoffice ? (
          <>
            <Geo color="var(--ruki-cyan)" height="50px" width="40px" />
            <br />
            Выбирайте выгодные заказы с&nbsp;заранее известной ценой
            в&nbsp;удобном районе
          </>
        ) : (
          <>
            Вы&nbsp;выбираете услугу и&nbsp;отвечаете на&nbsp;уточняющие
            вопросы. Мы&nbsp;называем стоимость работы
          </>
        )}
      </StaticLayout.Lead>

      <StaticLayout.Lead
        number={isBackoffice ? undefined : 2}
        component="li"
        extraClassName={cx('how-we-work__step', {
          'how-we-work__step_backoffice': isBackoffice,
        })}
      >
        {isBackoffice ? (
          <>
            <Rouble color="var(--ruki-cyan)" />
            <br />
            Выполняйте работу и&nbsp;получайте оплату. Без&nbsp;задержек
          </>
        ) : (
          <>
            После оформления заказа мы&nbsp;перезваниваем, чтобы согласовать
            время приезда мастера
          </>
        )}
      </StaticLayout.Lead>

      <StaticLayout.Lead
        number={isBackoffice ? undefined : 3}
        component="li"
        padding="none"
        extraClassName={cx('how-we-work__step', {
          'how-we-work__step_backoffice': isBackoffice,
        })}
      >
        {isBackoffice ? (
          <>
            <Star color="var(--ruki-cyan)" height="50px" width="50px" />
            <br />
            Отчитывайтесь и&nbsp;получайте отзывы. Больше отличных
            отзывов&nbsp;&mdash; больше заказов
          </>
        ) : (
          <>
            Подберём мастера, который приедет и&nbsp;выполнит заказ.
            Вы&nbsp;оплачиваете ему работу
          </>
        )}
      </StaticLayout.Lead>
    </StaticLayout.Widget>
  );
};

export default HowWeWork;
