import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import cx from 'classnames';

import Arrow from '../icon/components/Arrow';
import './Carousel.scss';

interface OptionsType {
  reInitOnChange?: boolean;
  onKeyLeft?: () => void;
  onKeyRight?: () => void;
  onSlideChange?: (index: number) => void;
}

interface OwnProps {
  carouselRef: any;
  carouselAPI: any;
  children: ReactNode[];
  options?: OptionsType;
  bullets?: (props: Partial<BulletsProps>) => ReactNode;
  leftNav?: (props: Partial<NavProps>) => ReactNode;
  rightNav?: (props: Partial<NavProps>) => ReactNode;
  emptyChild?: ReactNode;
  containerProps?: {
    extraClassName?: string;
    component?: any;
  };
}

const DEFAULT_OPTIONS: OptionsType = {
  onSlideChange: () => {},
  onKeyLeft: () => {},
  onKeyRight: () => {},
  reInitOnChange: false,
};

const Carousel: FunctionComponent<OwnProps> & {
  Bullets: typeof Bullets;
  LeftNav: typeof LeftNav;
  RightNav: typeof RightNav;
  EmptyChild: typeof EmptyChild;
} = ({
  carouselAPI,
  carouselRef,
  children,
  options = {},
  bullets = props => <Carousel.Bullets {...(props as any)} />,
  leftNav = props => <Carousel.LeftNav {...(props as any)} />,
  rightNav = props => <Carousel.RightNav {...(props as any)} />,
  emptyChild = null,
  containerProps = {extraClassName: '', component: 'div'},
}) => {
  const combinedOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  const {extraClassName, component: Component = 'div'} = containerProps;

  const {
    reInitOnChange,
    onSlideChange,
    onKeyLeft,
    onKeyRight,
  } = combinedOptions;

  const [slidesInView, setSlidesInView] = useState<number[]>([]);

  const onKeyDown = useCallback(
    event => {
      if (!carouselAPI) {
        return;
      }
      if (event.code === 'ArrowRight' && carouselAPI.canScrollNext()) {
        carouselAPI.scrollNext();
        onKeyRight?.();
      }
      if (event.code === 'ArrowLeft' && carouselAPI.canScrollPrev()) {
        carouselAPI.scrollPrev();
        onKeyLeft?.();
      }
    },
    [carouselAPI],
  );

  useEffect(() => {
    if (reInitOnChange || !carouselAPI) return;
    carouselAPI.reInit();
  }, [carouselAPI, children, reInitOnChange]);

  const scrollPrev = useCallback<() => void>(() => {
    if (!carouselAPI) {
      return;
    }

    if (!carouselAPI.canScrollPrev()) {
      return;
    }
    carouselAPI.scrollPrev();
  }, [carouselAPI]);

  const scrollNext = useCallback<() => void>(() => {
    if (!carouselAPI) {
      return;
    }

    if (!carouselAPI.canScrollNext()) {
      return;
    }
    carouselAPI.scrollNext();
  }, [carouselAPI]);

  const scrollTo = useCallback<(index: number) => void>(
    index => {
      return (
        carouselAPI &&
        carouselAPI.scrollTo(Math.floor(index / (slidesInView.length || 1)))
      );
    },
    [carouselAPI, slidesInView.length],
  );

  const onSelect = useCallback(() => {
    if (!carouselAPI) return;

    if (onSlideChange) {
      onSlideChange(carouselAPI.selectedScrollSnap());
    }
    setSlidesInView(carouselAPI.slidesInView(true));
  }, [carouselAPI, onSlideChange]);

  useEffect(() => {
    if (carouselAPI) {
      onSelect();
      carouselAPI.on('select', onSelect);
    }
    return () => {
      carouselAPI?.off('select', onSelect);
    };
  }, [carouselAPI, onSelect]);

  if (!children?.length) return <>{emptyChild}</>;

  return (
    <div
      className="carousel"
      onKeyDown={onKeyDown}
      tabIndex={-1}
      role="none"
      data-testid="carousel"
    >
      <div className="carousel__viewport" ref={carouselRef}>
        <Component
          className={cx('carousel__container', {
            [extraClassName as string]: !!extraClassName,
          })}
          role="list"
        >
          {children}
        </Component>
      </div>

      {bullets &&
        bullets({
          onClick: scrollTo,
          slidesCount: children.length,
          slidesInView,
        })}

      {leftNav &&
        leftNav({
          onClick: scrollPrev,
          enabled: carouselAPI?.canScrollPrev(),
        })}

      {rightNav &&
        rightNav({
          onClick: scrollNext,
          enabled: carouselAPI?.canScrollNext(),
        })}
    </div>
  );
};

interface BulletsProps {
  slidesCount: number;
  onClick: (index: number) => void;
  onClickCapture?: (index: number) => void;
  extraClassName?: string;
  slidesInView: number[];
}

const Bullets: FunctionComponent<BulletsProps> = ({
  slidesCount,
  onClick,
  onClickCapture,
  extraClassName,
  slidesInView,
}) => {
  if (!slidesCount || slidesCount <= slidesInView.length) return null;
  const slideIndexes = Array.from({length: slidesCount}, (_, i) => i);

  return (
    <div
      className={cx('carousel__bullets', {
        [`${extraClassName}`]: !!extraClassName,
      })}
      data-testid="carousel-bullets"
    >
      {slideIndexes.map((i: number) => (
        <button
          key={i}
          type="button"
          aria-label="Carousel bullet"
          onClickCapture={() => onClickCapture?.(i)}
          onClick={() => onClick(i)}
          className={cx('carousel__bullet', {
            'carousel__bullet--active': slidesInView.includes(i),
            'carousel__bullet--inactive': !slidesInView.includes(i),
          })}
        />
      ))}
    </div>
  );
};

interface NavProps {
  enabled: boolean;
  onClick: () => void;
  onClickCapture?: () => void;
  fixed?: boolean;
  extraClassName?: string;
  children?: JSX.Element;
}

const LeftNav: FunctionComponent<NavProps> = ({
  enabled,
  onClick,
  onClickCapture,
  fixed,
  extraClassName,
  children = <Arrow width="60px" height="60px" direction="left" />,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <button
      type="button"
      className={cx('carousel__control carousel__control--left', {
        'carousel__control--fixed': fixed,
        [`${extraClassName}`]: !!extraClassName,
      })}
      onClick={onClick}
      onClickCapture={onClickCapture}
      data-testid="carousel__left-nav"
    >
      {children}
    </button>
  );
};

const RightNav: FunctionComponent<NavProps> = ({
  enabled,
  onClick,
  onClickCapture,
  fixed,
  extraClassName,
  children = <Arrow width="60px" height="60px" direction="right" />,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <button
      type="button"
      className={cx('carousel__control carousel__control--right', {
        'carousel__control--fixed': fixed,
        [`${extraClassName}`]: !!extraClassName,
      })}
      onClick={onClick}
      onClickCapture={onClickCapture}
      data-testid="carousel__right-nav"
    >
      {children}
    </button>
  );
};

interface EmptyChildProps {
  children: ReactNode;
}

const EmptyChild: FunctionComponent<EmptyChildProps> = ({
  children,
}: EmptyChildProps) => {
  return (
    <div className="carousel__empty-child" data-testid="carousel__empty-child">
      {children}
    </div>
  );
};

Carousel.Bullets = Bullets;
Carousel.LeftNav = LeftNav;
Carousel.RightNav = RightNav;
Carousel.EmptyChild = EmptyChild;

export default Carousel;
