import React, {FunctionComponent} from 'react';

import Footer from 'client/components/Footer';
import Notice from 'client/components/Notice';
import Navbar from 'client/components/Navbar';
import Content from 'client/components/Content';

import './PlainLayout.css';

interface OwnProps {
  withFooter?: boolean;
  fluid?: boolean;
}

const PlainLayout: FunctionComponent<OwnProps> = ({
  withFooter = true,
  fluid = false,
  children,
}) => {
  const Wrapper = fluid ? React.Fragment : Content;
  return (
    <div className="plain-layout">
      <Navbar />

      <Notice />

      <Wrapper>{children}</Wrapper>

      {withFooter && <Footer />}
    </div>
  );
};

export default PlainLayout;
