import React, {FunctionComponent, Suspense} from 'react';
import StaticLayout from 'client/components/Layouts/StaticLayout';

import './Elaboration.scss';

export type ElaborationTopicType =
  | 'specialist'
  | 'price'
  | 'kitchen'
  | 'guarantee'
  | 'compensation';

interface OwnProps {
  topic: ElaborationTopicType;
  isKitchen: boolean;
}

const Elaboration: FunctionComponent<OwnProps> = ({isKitchen, topic}) => {
  const Component = (() => {
    if (topic === 'price') {
      return React.lazy(
        () => import(/* webpackChunkName: "elaboration" */ './topics/Price'),
      );
    }

    if (topic === 'specialist') {
      return React.lazy(
        () =>
          import(/* webpackChunkName: "elaboration" */ './topics/Specialist'),
      );
    }

    if (topic === 'kitchen') {
      return React.lazy(
        () => import(/* webpackChunkName: "elaboration" */ './topics/Kitchen'),
      );
    }

    if (topic === 'guarantee' || topic === 'compensation') {
      return React.lazy(
        () =>
          import(/* webpackChunkName: "elaboration" */ './topics/Guarantee'),
      );
    }

    return null as never;
  })();

  return (
    <Suspense fallback={<StaticLayout.Block loading />}>
      <Component isKitchen={isKitchen} />
    </Suspense>
  );
};

export default Elaboration;
