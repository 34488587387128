import React, {FunctionComponent} from 'react';

interface OwnProps {
  color?: string;
}

const TwoGisIcon: FunctionComponent<OwnProps> = ({
  color = '#000',
}: OwnProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 50 16">
      <path
        fill={color}
        d="M47.2 5.9h2.2c-.3-2.2-1.9-3.4-4.1-3.4-2.3 0-3.8 1.2-3.8 3.3 0 2.3 2 2.9 3 3 1.3.3 2.6.4 2.6 1.6 0 .8-.6 1.2-1.5 1.2-1.2 0-2-.6-2.1-1.7h-2.3c.1 2.3 1.8 3.6 4.2 3.6 2.2 0 4-1 4-3.4s-2.1-2.8-3.6-3c-1-.2-2-.4-2-1.4 0-.8.5-1.3 1.5-1.3s1.7.7 1.9 1.5Zm-7.2-3h-2.2V13H40V3Zm-8.7 6.6h2.8c-.4 1.2-1.4 1.7-2.5 1.7-2 0-3-1.6-3-3.2s1-3.2 3-3.2c1.2 0 2.2.6 2.5 1.6h2.3C36 4 34 2.7 31.6 2.7c-3 0-5.2 2-5.2 5.3a5 5 0 0 0 5.2 5.3c2.7 0 4.8-1.8 4.8-4.7v-.8h-5v1.7Zm-9.8-6.8c-2.4 0-4.2 1.6-4.2 4.2h2.2c0-1.1.7-2.2 2-2.2 1 0 1.7.8 1.7 1.7 0 .9-.7 1.4-1.9 2l-4 1.6v3.1h8.3v-2H20l2.5-1.1c1.9-.8 3-2 3-3.7 0-2.2-1.7-3.6-4-3.6Z"
      />
      <path
        fill="#29B24A"
        d="M11.5 9.5c-2.8 0-3.3 1.8-3.4 3.2l-.1.7h-.6v-.7c-.2-1.4-.6-3.2-3.3-3.2-.5-1-.7-1.7-.7-2.6 0-2.2 1.8-4.2 4.3-4.2s4.3 2 4.3 4.2c0 .9 0 1.7-.5 2.6ZM7.7 0A7.9 7.9 0 0 0 0 8c0 4.4 3.5 8 7.7 8 4.3 0 7.7-3.6 7.7-8S12 0 7.7 0Z"
      />
    </svg>
  );
};

export default TwoGisIcon;
