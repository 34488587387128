import {SearchResultType} from 'client/types/search';
import handsRuGetDataForMainPage from 'queries/handsRuGetDataForMainPage_2.graphql';
import {MainPageCategoryGroupType} from 'client/types/main-page-category';
import {MainPageServiceType} from 'client/types/main-page-service';
import {
  ExternalReview,
  ExternalReviewSource,
} from 'client/types/external-review';
import {ImageForBanner} from 'client/types/image';

interface ExternalReviewStat {
  link: string;
  numReviews: number;
  source: ExternalReviewSource;
  avgRating: number;
}

interface BannerType {
  title: string;
  description: string;
  actionCaption: string;
  actionUrl: string;
  images: ImageForBanner[];
  colors: {
    title: string;
    background: string;
  };
}

export interface HandsRuGetDataForMainPageData {
  mainServices: MainPageServiceType[];
  categoryGroups: MainPageCategoryGroupType[];
  serviceConnection: {totalCount: number};
  popularSearchSuggestions: SearchResultType[];
  handsCommonInfo: {
    numClients: number;
    externalReviews: ExternalReview[];
    externalReviewCumulativeStat: ExternalReviewStat[];
    banner: BannerType | null;
  };
}

export interface HandsRuGetDataForMainPageVariables {
  bannerKind: 'WEB' | 'WEB_DESKTOP';
}

export default handsRuGetDataForMainPage;
