import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import Link from '@pollex/components/link';
import Button from '@pollex/components/button';
import Row from 'client/components/Grid/Row';
import {useAnalytics} from 'client/contexts/Analytics';
import {useLocation} from 'client/contexts/Location';
import {toClassifier} from 'client/utils/urls';

import './MainHowTo.scss';

const MainHowTo: FunctionComponent = () => {
  const location = useLocation();
  const {handleEvent} = useAnalytics();
  return (
    <div className="main-how-to">
      <ol>
        <Row
          verticalAlign="middle"
          justify="flex-start"
          component="li"
          extraClassName={cx('main-how-to__item', 'main-how-to__item_one')}
        >
          <div className="main-how-to__item-text">Выберите услугу</div>
        </Row>
        <Row
          verticalAlign="middle"
          justify="flex-start"
          component="li"
          wrap={false}
          extraClassName={cx('main-how-to__item', 'main-how-to__item_two')}
        >
          <div className="main-how-to__item-text">Ответьте на вопросы</div>
        </Row>
        <Row
          verticalAlign="middle"
          justify="flex-start"
          component="li"
          extraClassName={cx('main-how-to__item', 'main-how-to__item_three')}
        >
          <div className="main-how-to__item-text">
            Дождитесь <span className="main-how-to__extra">нашего </span>звонка
          </div>
        </Row>
      </ol>

      <Button
        size="medium"
        component={Link}
        href={toClassifier().serialize(location)}
        onClickCapture={handleEvent('choose_service_button_click', {})}
        design="filled"
        extraClassName="main-how-to__button"
      >
        Выбрать услугу
      </Button>
    </div>
  );
};

export default MainHowTo;
