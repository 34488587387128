import React, {FunctionComponent} from 'react';

interface OwnProps {
  color?: string;
}

const GooglePlayIcon: FunctionComponent<OwnProps> = ({
  color = '#000',
}: OwnProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 85 20">
      <path
        fill="url(#google_a)"
        fillRule="evenodd"
        d="M.3.3C.1.6 0 .9 0 1.4v17c0 .6.1 1 .4 1.2L10 10v-.2L.4.2v.1Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#google_b)"
        fillRule="evenodd"
        d="M13.2 13.3 10 10v-.2l3.2-3.2L17 8.9c1.1.6 1.1 1.6 0 2.3l-3.7 2.1h-.1Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#google_c)"
        fillRule="evenodd"
        d="M13.3 13.2 10 9.9.3 19.6c.4.4 1 .4 1.6 0l11.4-6.4Z"
        clipRule="evenodd"
      />
      <path
        fill="url(#google_d)"
        fillRule="evenodd"
        d="M13.3 6.7 1.8.3C1.3-.1.7-.1.4.3L10 10l3.3-3.2Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M13.2 13.1 2 19.5c-.7.4-1.2.3-1.6 0v.1c.4.3 1 .4 1.6 0l11.3-6.4h-.1Z"
        clipRule="evenodd"
        opacity=".2"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M17 11 13.2 13v.1l3.8-2.1c.6-.4.8-.8.8-1.2 0 .4-.3.8-.8 1Z"
        clipRule="evenodd"
        opacity=".1"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m2 .4 15 8.5c.5.3.8.7.8 1 0-.4-.2-.8-.8-1L2 .2C.9-.4 0 .2 0 1.4v.1C0 .3.9-.2 2 .4Z"
        clipRule="evenodd"
        opacity=".3"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M21.5 9.4a5 5 0 0 1 5-5c1.6 0 2.6.6 3.5 1.4l-1 1a3.6 3.6 0 0 0-6 2.6c0 2 1.6 3.6 3.5 3.6 1.3 0 2-.5 2.6-1 .3-.4.6-1 .7-1.7h-3.2V9H31l.1.9c0 1-.3 2.3-1.2 3.2-.9.9-2 1.4-3.4 1.4a5 5 0 0 1-5-5ZM35 8a3.1 3.1 0 0 0-3.2 3.2c0 1.8 1.4 3.2 3.2 3.2a3.2 3.2 0 0 0 3.2-3.2c0-1.9-1.4-3.2-3.2-3.2Zm0 5.1c-1 0-1.8-.8-1.8-2 0-1 .8-1.9 1.8-1.9s1.8.8 1.8 2c0 1.1-.8 1.9-1.8 1.9Zm7-5.1a3.1 3.1 0 0 0-3.2 3.2c0 1.8 1.4 3.2 3.2 3.2a3.2 3.2 0 0 0 3.2-3.2c0-1.9-1.4-3.2-3.2-3.2Zm0 5.1c-1 0-1.8-.8-1.8-2 0-1 .8-1.9 1.8-1.9s1.8.8 1.8 2c0 1.1-.8 1.9-1.8 1.9Zm8.6-5v.6a2.2 2.2 0 0 0-1.7-.7c-1.6 0-3.1 1.4-3.1 3.2 0 1.8 1.5 3.2 3 3.2.8 0 1.4-.4 1.7-.8v.5c0 1.2-.6 1.9-1.6 1.9-.9 0-1.4-.6-1.6-1.2l-1.3.5a3 3 0 0 0 2.9 2c1.6 0 3-1 3-3.4V8.2h-1.3Zm-1.6 5c-1 0-1.8-.8-1.8-2 0-1 .8-1.9 1.8-1.9s1.7.9 1.7 2c0 1-.8 1.9-1.7 1.9Zm3.9-8.3h1.4v9.4h-1.4V4.8ZM58 13c-.8 0-1.3-.3-1.6-1l4.3-1.7-.2-.4c-.2-.7-1-2-2.7-2-1.7 0-3 1.3-3 3.2 0 1.8 1.3 3.2 3.1 3.2 1.5 0 2.4-1 2.7-1.4l-1-.8c-.4.6-1 1-1.7.9Zm-.2-3.9c.6 0 1 .3 1.2.7L56.3 11a1.8 1.8 0 0 1 1.6-1.9Zm9-4.4h-3.3v9.4H65v-3.6h2c1.5 0 3-1.1 3-3 0-1.7-1.5-2.8-3-2.8Zm0 4.5h-2V6.1h2c1.1 0 1.7.9 1.7 1.6 0 .7-.6 1.6-1.6 1.6Zm5.3 4.9V4.8h-1.4v9.4h1.4Zm6.3 0v-3.6c0-1.7-1.2-2.6-2.9-2.6-1 0-2 .4-2.4 1.4l1.2.5c.3-.5.7-.7 1.3-.7.7 0 1.4.5 1.5 1.2v.1a3.1 3.1 0 0 0-1.5-.3c-1.4 0-2.7.7-2.7 2s1 2.2 2.3 2.2a2 2 0 0 0 1.8-1v.8h1.4Zm-3-1.1c-.5 0-1.1-.2-1.1-.8 0-.7.8-1 1.5-1 .6 0 .9.1 1.2.3a1.7 1.7 0 0 1-1.6 1.5Zm8-5-1.7 4.1-1.7-4h-1.5l2.5 5.7-1.4 3.1H81L85 8.2h-1.6Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="google_a"
          x1="9.1"
          x2="-6.2"
          y1="1.2"
          y2="5.3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0" stopColor="#00A1FF" />
          <stop offset=".3" stopColor="#00BEFF" />
          <stop offset=".5" stopColor="#00D2FF" />
          <stop offset=".8" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="google_b"
          x1="18.4"
          x2="-.3"
          y1="9.9"
          y2="9.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset=".4" stopColor="#FFBD00" />
          <stop offset=".8" stopColor="orange" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="google_c"
          x1="11.5"
          x2="-.8"
          y1="11.7"
          y2="32.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="google_d"
          x1="-2.1"
          x2="3.4"
          y1="-5.4"
          y2="3.9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset=".1" stopColor="#2DA771" />
          <stop offset=".5" stopColor="#15CF74" />
          <stop offset=".8" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GooglePlayIcon;
