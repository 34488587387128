import React, {FunctionComponent} from 'react';
import cx from 'classnames';

import './ReviewNPS.scss';

interface OwnProps {
  nps: number;
  size?: 'small';
  color?: string;
  extraClassName?: string;
}

const Stars: FunctionComponent<OwnProps & {selected?: boolean}> = ({
  color = 'var(--ruki-blue)',
  size,
  nps,
  extraClassName,
  selected,
}) => {
  const [width, height] = size === 'small' ? [82, 24] : [208, 32];
  return (
    <div
      className={cx(
        'review-nps',
        {[`review-nps_${nps}`]: selected},
        {[`review-nps_${size}`]: size},
        extraClassName,
      )}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 208 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m11.2 11.6-10.5.5-.7 1.5L8.6 19 5.1 30.5l1.1 1 9.8-7.1L26.6 32l.8-.8L23.6 19l8.4-6.8-1.3-1.4-9.7.4L17.2.7 15.5.1V0l-4.3 11.6ZM55.2 11.6l-10.5.5-.7 1.5 8.6 5.5-3.5 11.4 1.1 1 9.8-7.1L70.6 32l.8-.8L67.6 19l8.4-6.8-1.3-1.4-9.7.4L61.2.7 59.5.1V0l-4.3 11.6ZM99.2 11.6l-10.5.5-.7 1.5 8.6 5.5-3.5 11.4 1.1 1 9.8-7.1 10.6 7.6.8-.8-3.8-12.1 8.4-6.8-1.3-1.4-9.7.4L105.2.7l-1.7-.6V0l-4.3 11.6ZM143.2 11.6l-10.5.5-.7 1.5 8.6 5.5-3.5 11.4 1.1 1 9.8-7.1 10.6 7.6.8-.8-3.8-12.1 8.4-6.8-1.3-1.4-9.7.4L149.2.7l-1.7-.6V0l-4.3 11.6ZM187.2 11.6l-10.5.5-.7 1.5 8.6 5.5-3.5 11.4 1.1 1 9.8-7.1 10.6 7.6.8-.8-3.8-12.1 8.4-6.8-1.3-1.4-9.7.4L193.2.7l-1.7-.6V0l-4.3 11.6Z"
          fill={selected ? color : '#C9C9C9'}
        />
      </svg>
    </div>
  );
};

const ReviewNPS: FunctionComponent<OwnProps> = props => {
  const {nps, size} = props;
  if (!Number.isInteger(nps)) return null;
  return (
    <div
      className={cx('review-nps__wrapper', {
        [`review-nps__wrapper_${size}`]: size,
      })}
    >
      <Stars {...props} />
      <Stars {...props} selected />
    </div>
  );
};

export default ReviewNPS;
