import React, {FunctionComponent, MouseEventHandler} from 'react';

import cx from 'classnames';
import typographize from '@pollex/utils/typographize';
import Link from '@pollex/components/link';
import Tile, {TileProps} from 'client/ui/Tile';
import {useTileColors} from 'client/contexts/Theme';
import {HTML} from 'client/types/html';
import {CategorySlugType} from 'client/types/slug';
import PlaceholderProgress from 'client/components/Progress/PlaceholderProgress';
import Arrow from '@pollex/components/icon/components/ArrowTailed2';

import './MainPageTile.scss';

export interface OwnProps {
  title: string;
  href: string;
  price: JSX.Element;
  categorySlug: CategorySlugType;
  onClickCapture: MouseEventHandler;
  details?: HTML;
  tileProps?: Partial<TileProps>;
}

const MainPageTile: FunctionComponent<OwnProps> & {
  Placeholder: typeof Placeholder;
} = ({
  title,
  tileProps,
  details,
  categorySlug,
  price,
  href,
  onClickCapture,
}) => {
  const [background, color] = useTileColors(categorySlug, true);

  return (
    <Tile
      data-shmid="tile-link"
      href={href}
      onClickCapture={onClickCapture}
      color={color}
      backgroundColor={background}
      component={Link}
      extraClassName="main-page-tile"
      external={!!href.startsWith('http')}
      {...tileProps}
    >
      <div className="main-page-tile__content">
        <div className="main-page-tile__upper-content">
          <div data-shmid="main-tile-title" className="main-page-tile__title">
            {typographize(title)}
          </div>

          <Arrow
            extraClassName="main-page-tile__arrow"
            color={color}
            width="20px"
            height="20px"
          />

          {details && (
            <div
              data-shmid="tile-service-name"
              className="main-page-tile__details"
            >
              {details}
            </div>
          )}
        </div>

        <div className="main-page-tile__price">{price}</div>
      </div>
    </Tile>
  );
};

const Placeholder: FunctionComponent<Pick<OwnProps, 'categorySlug'>> = ({
  categorySlug,
}) => {
  const [background] = useTileColors(categorySlug);

  return (
    <Tile
      backgroundColor={background}
      extraClassName={cx('main-page-tile', 'main-page-tile_placeholder')}
    >
      <PlaceholderProgress />
    </Tile>
  );
};

MainPageTile.Placeholder = Placeholder;

export default MainPageTile;
