import {useEffect, Ref} from 'react';
import {useAnalytics} from 'client/contexts/Analytics';
import {
  EventKey,
  EventValue,
} from 'client/contexts/Analytics/trackers/TrackerAPI';
import {useInView, IntersectionOptions} from 'react-intersection-observer';

export default function useAnalyticsEventOnView<
  E extends HTMLElement = HTMLElement
>(
  name: EventKey,
  params: EventValue,
  options: IntersectionOptions = {triggerOnce: true, threshold: 0.75},
): Ref<E> {
  const [ref, inView] = useInView(options);
  const {handleEvent} = useAnalytics();

  useEffect(() => {
    handleEvent(name, params)();
  }, [inView]);

  return ref;
}
