import React, {FunctionComponent, Fragment} from 'react';
import cx from 'classnames';
import {useLocation} from 'client/contexts/Location';
import {useAnalytics} from 'client/contexts/Analytics';
import {ThemeType, useTheme} from 'client/contexts/Theme';
import {useQuery} from '@apollo/client';
import {
  useBusinessComponents,
  UnderConstructionNoticeType,
} from 'client/contexts/City';
import Link from '@pollex/components/link';
import {toCategory} from 'client/utils/urls';
import typographize from '@pollex/utils/typographize';
import {isCategoryAvailable} from 'client/contexts/City/utils';
import handsRuGetDataForMainPage, {
  HandsRuGetDataForMainPageData,
  HandsRuGetDataForMainPageVariables,
} from 'query-wrappers/HandsRuGetDataForMainPage';
import StaticLayout from 'client/components/Layouts/StaticLayout';
import Arrow from '@pollex/components/icon/components/ArrowTailed2';
import {useMeta} from 'client/contexts/Meta';
import {getServiceLabel} from '../../utils';
import MainList from '../MainList';
import Banner from '../Banner';

import './MainCategoryList.scss';

const MainCategoryList: FunctionComponent = () => {
  const {isMobile} = useMeta();
  const location = useLocation();
  const {handleEvent} = useAnalytics();
  const theme = useTheme();
  const [underConstructionNotice, leroyKitchenNotice] = useBusinessComponents<
    UnderConstructionNoticeType[]
  >(['UNDER_CONSTRUCTION_NOTICE', 'LEROY_KITCHEN_NOTICE']);
  const {data, loading} = useQuery<
    HandsRuGetDataForMainPageData,
    HandsRuGetDataForMainPageVariables
  >(handsRuGetDataForMainPage, {
    variables: {bannerKind: isMobile ? 'WEB' : 'WEB_DESKTOP'},
  });

  const handleClick = (e: React.MouseEvent<any>, category) => {
    e.preventDefault();
    location.history.push(toCategory(category).serialize(location));
  };

  if (loading) {
    return Array.from({length: 3}, (_, i) => <MainList key={i} isLoading />);
  }

  if (!data) return null;

  return (
    <>
      <StaticLayout.Title level={2} extraClassName="main-category-list__title">
        Что сделать
      </StaticLayout.Title>

      {data.handsCommonInfo.banner && (
        <Banner {...data.handsCommonInfo.banner} />
      )}

      {data.categoryGroups.map(({name, categories}, i) => (
        <Fragment key={name}>
          <MainList heading={name} isLoading={loading && i === 0}>
            {categories.map(({slug, images}) => {
              const category = theme.cache.get(slug) as ThemeType;

              if (!category?.serviceCount) {
                return null;
              }

              return (
                <Link
                  key={slug}
                  onClick={e => handleClick(e, category)}
                  href={toCategory(category).serialize(location)}
                  className={cx('main-category-list__item', {
                    'main-category-list__item_unavailable': !(
                      isCategoryAvailable(slug, underConstructionNotice) &&
                      isCategoryAvailable(slug, leroyKitchenNotice)
                    ),
                  })}
                  onClickCapture={handleEvent('main_category_click', {
                    slug,
                  })}
                >
                  <div
                    className="main-category-list__image"
                    style={{
                      backgroundImage: `url(${images[0]?.file})`,
                    }}
                  />
                  <div className="main-category-list__text-container">
                    <p className="main-category-list__item-name">
                      {typographize(category.name)}
                    </p>
                    <p className="main-category-list__service-count">
                      {getServiceLabel(category.serviceCount)}
                    </p>

                    <Arrow
                      extraClassName="main-category-list__text-container_arrow"
                      color="var(--ruki-white)"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </Link>
              );
            })}
          </MainList>
        </Fragment>
      ))}
    </>
  ) as any;
};

export default MainCategoryList;
