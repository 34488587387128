import React, {FunctionComponent} from 'react';

interface OwnProps {
  color?: string;
}

const YandexMapsIcon: FunctionComponent<OwnProps> = ({
  color = '#000',
}: OwnProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 56 16">
      <path
        fill={color}
        d="M52.8 14c1.1 0 1.9-.3 2.4-.7v-1.9c-.5.4-1.3.7-2.2.7-1.7 0-2.4-1.3-2.4-3.4 0-2.1.8-3.3 2.4-3.3.9 0 1.8.4 2.2.7V4c-.5-.3-1.3-.5-2.5-.5-3 0-4.6 2.2-4.6 5.2 0 3.3 1.6 5.1 4.7 5.1Zm-14.7-1v-1.9c-.7.5-2 1-3 1-1.8 0-2.4-.8-2.5-2.5h5.6V8.4c0-3.5-1.5-4.8-3.8-4.8-2.9 0-4.3 2.2-4.3 5.2 0 3.5 1.7 5.1 4.7 5.1 1.5 0 2.6-.4 3.3-.8ZM16.6 3.9v4h-3.2v-4h-2.3v10h2.3V9.5h3.2v4.1h2.3v-10h-2.3Zm13 8h-1v-8h-7v.8c0 2.5 0 5.6-1 7.3H20V16h2.2v-2.3h5.3V16h2.1v-4.1Zm16 2h2.7l-3.8-5.4L48 3.8h-2.4l-3.3 4.6V3.8h-2.4v10h2.4v-5l3.4 5ZM34.3 5.3c1.2 0 1.5 1 1.5 2.3v.2h-3.2c0-1.6.6-2.5 1.7-2.5ZM26.2 12h-3.4c.7-1.5.9-4.2.9-6v-.3h2.5V12Z"
      />
      <path
        fill="#FC3F1D"
        d="M9.1 13.7H6.7V2h-1c-2 0-3 1-3 2.4 0 1.7.7 2.4 2.1 3.4l1.3.8-3.5 5.2H0l3.1-4.6C1.3 7.8.3 6.6.3 4.4.3 1.8 2.2 0 5.6 0h3.5v13.7Z"
      />
    </svg>
  );
};

export default YandexMapsIcon;
