import React, {ReactNode, FunctionComponent} from 'react';

import cx from 'classnames';
import './Grid.css';
import {Justification, Alignment} from './types';

export interface RowProps {
  children: ReactNode;
  extraClassName?: string;
  component?: any;
  justify?: Justification;
  verticalAlign?: Alignment;
  wrap?: boolean;
}

const Row: FunctionComponent<RowProps> = ({
  children,
  wrap = true,
  extraClassName,
  justify = 'space-between',
  verticalAlign = 'top',
  component: ComponentProp = 'div',
  ...restOfProps
}) => (
  <ComponentProp
    className={cx(
      'grid',
      'grid_row',
      `grid_justify_${justify}`,
      `grid_align_${verticalAlign}`,
      {grid_wrap: wrap},
      extraClassName,
    )}
    {...restOfProps}
  >
    {children}
  </ComponentProp>
);

export default Row;
