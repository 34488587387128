import React, {FunctionComponent} from 'react';

const CoverPrice: FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="383"
    height="216"
    viewBox="0 0 383 216"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m218 104.223 1.989 12.089 49.127 1.606-9.447 4.118 2.486 8.837 9.746 3.314-8.055 3.013 2.784 10.042 8.652 3.113-7.26 2.31L270.31 161l49.624-4.218H383v-39.567h-63.264L295.072 69l-9.447 5.6237 10.641 29.5993H218Z"
      fill="#000"
    />
    <path
      d="m71 80-5.1175 3.1322h10.235L71 80Zm17-50.5366c0 1.9591-1.0008 5.7708-3.1581 11.1186-2.0527 5.0885-4.8284 10.8071-7.6607 16.2325-2.8227 5.4069-5.6525 10.4294-7.7804 14.1058a369.1314 369.1314 0 0 1-2.5635 4.3628 244.5053 244.5053 0 0 1-.8966 1.4891 22.9597 22.9597 0 0 1-.0559.0918l-.0022.0036-.0003.0005c0-.0001.0002-.0003 5.1177 3.1319s5.1179 3.1317 5.1183 3.131a.0344.0344 0 0 1 .0012-.002l.0041-.0067a5.389 5.389 0 0 1 .0144-.0236l.0532-.0873c.0462-.0758.1135-.1866.2005-.3304.174-.2875.4269-.7073.7476-1.2441a382.9618 382.9618 0 0 0 2.6474-4.5053c2.1846-3.7745 5.1048-8.9564 8.0321-14.5638 2.9177-5.5889 5.892-11.6946 8.1518-17.2966C98.1258 39.7286 100 33.9836 100 29.4634H88ZM71 80c5.1175-3.1322 5.1177-3.132 5.1177-3.1319l-.0003-.0005-.0022-.0036a369.1314 369.1314 0 0 1-3.516-5.9437c-2.1279-3.6764-4.9577-8.6989-7.7804-14.1058-2.8323-5.4254-5.6081-11.144-7.6607-16.2325C55.0008 35.2342 54 31.4225 54 29.4634H42c0 4.5202 1.8742 10.2652 4.0294 15.6078 2.2599 5.602 5.2341 11.7077 8.1518 17.2966 2.9273 5.6074 5.8475 10.7893 8.0321 14.5638a382.9618 382.9618 0 0 0 2.6474 4.5053c.3207.5368.5736.9566.7476 1.2441.087.1438.1543.2546.2005.3304l.0532.0873.0144.0236.0041.0067a.0344.0344 0 0 1 .0012.002c.0004.0007.0008.0012 5.1183-3.131ZM54 29.4634C54 19.7052 61.7234 12 71 12V0C54.8715 0 42 13.3046 42 29.4634h12ZM71 12c9.2766 0 17 7.7052 17 17.4634h12C100 13.3046 87.1285 0 71 0v12Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71 30 0 68.567l10 146.92h122l10-146.92L71 30Zm0 60c6.0751 0 11-4.9249 11-11s-4.9249-11-11-11-11 4.9249-11 11 4.9249 11 11 11Z"
      fill="var(--link-color)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71 46 12 78.02 20.3099 205H121.69L130 78.02 71 46Zm0 7L20 84.5896 27.1831 200l87.6339-4L124 83.5896 71 53Z"
      fill="#F0EFEF"
    />
    <path
      d="M71 80S48 42.4219 48 29.4634 58.2975 6 71 6"
      stroke="#000"
      strokeWidth="12"
    />
    <path
      d="M55.4414 142.508h3.5859V116h16.875c5.086 0 9.0586 1.277 11.918 3.832 2.8828 2.531 4.3242 6.246 4.3242 11.145v2.144c0 4.969-1.4414 8.766-4.3242 11.391-2.8828 2.601-6.8555 3.902-11.918 3.902h-4.9921v6.258h11.7421v5.976H70.9102V170H59.0273v-9.352h-3.5859v-5.976h3.5859v-6.258h-3.5859v-5.906Zm19.8633-16.418h-4.3945v13.324h4.3945c1.8047 0 3.1641-.504 4.0781-1.512.9375-1.007 1.4063-2.672 1.4063-4.992v-.703c0-2.273-.4571-3.855-1.3711-4.746-.9141-.914-2.2852-1.371-4.1133-1.371Z"
      fill="#F0EFEF"
    />
  </svg>
);

export default CoverPrice;
