import React, {FunctionComponent} from 'react';
import {useAnalytics} from 'client/contexts/Analytics';
import {useLocation} from 'client/contexts/Location';
import {useQuery} from '@apollo/client';
import handsRuGetDataForMainPage, {
  HandsRuGetDataForMainPageData,
  HandsRuGetDataForMainPageVariables,
} from 'query-wrappers/HandsRuGetDataForMainPage';
import {toService} from 'client/utils/urls';
import Price from 'client/ui/Price';
import StaticLayout from 'client/components/Layouts/StaticLayout';
import useMediaQuery from 'client/hooks/useMediaQuery';
import MainPageTile from '../MainPageTile';
import MainList from '../MainList';

import './MainServiceStrip.scss';

const MainServiceStrip: FunctionComponent = () => {
  const analytics = useAnalytics();
  const location = useLocation();
  const isMobileView = useMediaQuery('(max-width: 600px)');

  const {data, error, loading} = useQuery<
    HandsRuGetDataForMainPageData,
    HandsRuGetDataForMainPageVariables
  >(handsRuGetDataForMainPage, {
    variables: {
      bannerKind: 'WEB',
    },
  });

  if (error) throw error;

  const tiles = (data && data.mainServices) || [];
  const title = isMobileView ? 'Популярные услуги' : 'Популярное';

  if (loading) {
    return (
      <MainList heading="Популярное" extraClassName="main-service-strip">
        {[
          {slug: 'tualet-i-vannaia'},
          {slug: 'elektrika'},
          {slug: 'kukhnia'},
        ].map(({slug}, i) => (
          <MainPageTile.Placeholder
            key={`placeholder-${i}`} // yes, i, on purpose
            categorySlug={slug}
          />
        ))}
      </MainList>
    );
  }

  if (!tiles.length) {
    return null;
  }

  return (
    <MainList heading={title} extraClassName="main-service-strip">
      <StaticLayout.Block padding="none" extraClassName="main-service-tiles">
        {tiles.map(
          (
            {
              id,
              name,
              service,
              service: {price, minimumPrice},
              category,
              images,
            },
            index,
          ) => (
            <MainPageTile
              key={id}
              title={name}
              price={
                <>
                  От&nbsp;
                  <Price
                    amount={price.amount || minimumPrice.amount}
                    unit={price.unit || minimumPrice.unit}
                  />
                </>
              }
              categorySlug={category.slug}
              href={toService(service, {category}).serialize(location)}
              onClickCapture={analytics.handleEvent('main_service_click', {
                slug: service.slug,
              })}
              tileProps={{
                ...(!!images?.length &&
                  (index === 0 || index === 5) && {
                    iconPosition: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    icon: (
                      <div
                        className="main-service-tile__image"
                        style={{backgroundImage: `url(${images[0].file})`}}
                      />
                    ),
                  }),
              }}
            />
          ),
        )}
      </StaticLayout.Block>
    </MainList>
  );
};

export default MainServiceStrip;
