/* eslint-disable react/no-danger */
import React, {FunctionComponent} from 'react';
import {WithContext, SoftwareApplication} from 'schema-dts';

const ApplicationStructuredData: FunctionComponent = () => {
  function getMicroData(
    operatingSystem: string,
    downloadUrl: string,
    ratingValue: number,
    ratingCount: number,
  ): WithContext<SoftwareApplication> {
    return {
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      name: 'Руки — сервис домашнего ремонта',
      applicationCategory: 'https://schema.org/BusinessApplication',
      offers: {'@type': 'Offer', price: 0, priceCurrency: 'RUB'},
      operatingSystem,
      downloadUrl,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue,
        ratingCount,
      },
    };
  }

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            getMicroData(
              'ANDROID',
              'https://hands.onelink.me/Mf7U/ltobtsnf',
              4.2,
              1020,
            ),
          ),
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            getMicroData(
              'IOS',
              'https://hands.onelink.me/oQlQ/scngb1tl',
              4.3,
              2500,
            ),
          ),
        }}
      />
    </>
  );
};

export default ApplicationStructuredData;
