import React, {FunctionComponent} from 'react';
import loadable from '@loadable/component';
import {
  useBusinessComponents,
  UnderConstructionNoticeType,
  useCity,
} from 'client/contexts/City';
import StaticLayout from 'client/components/Layouts/StaticLayout';
import useMediaQuery from 'client/hooks/useMediaQuery';
import DryExternalReviews from '../DryExternalReviews';
import ExternalReviews from '../ExternalReviews';
import WhyChooseUs from '../WhyChooseUs';
import MainServiceStrip from '../MainServiceStrip';
import MainNoticeTile from '../MainNoticeTile';
import MainSearchBlock from '../MainSearchBlock';
import MainCategoryList from '../MainCategoryList';
import UsInMassMedia from '../UsInMassMedia';
import MainHowWeWork from '../MainHowWeWork';

import './MainCatalogBlock.scss';

const LazyGetOurAppWidget = loadable(
  () =>
    import(
      /* webpackChunkName: "catalog" */ 'client/components/Widgets/GetOurAppWidget'
    ),
);

const LazyFAQ = loadable(
  () =>
    import(/* webpackChunkName: "catalog" */ 'client/components/Widgets/FAQ'),
);

const MainCatalogBlock: FunctionComponent = () => {
  const [underConstruction, leroyKitchenNotice] = useBusinessComponents<
    UnderConstructionNoticeType[]
  >(['UNDER_CONSTRUCTION_NOTICE', 'LEROY_KITCHEN_NOTICE']);
  const {current} = useCity();
  const isMobileView = useMediaQuery('(max-width: 600px)');

  const specialistCount = current.stats?.activeSpecialistCount || 4642;

  return (
    <div className="main-catalog-block">
      {leroyKitchenNotice && (
        <MainNoticeTile businessComponentName="LEROY_KITCHEN_NOTICE" />
      )}

      <MainSearchBlock />

      <MainCategoryList />

      {isMobileView && (
        <>
          <DryExternalReviews />

          <WhyChooseUs specialistCount={specialistCount} />

          <ExternalReviews />

          <MainHowWeWork />
        </>
      )}

      {!underConstruction && <MainServiceStrip />}

      {isMobileView && (
        <>
          <StaticLayout.Block
            padding="none"
            extraClassName="main-catalog-block__get-our-app"
          >
            <LazyGetOurAppWidget title="Следите за&nbsp;заказом в&nbsp;приложении" />
          </StaticLayout.Block>
        </>
      )}

      <UsInMassMedia />

      {isMobileView && (
        <>
          <StaticLayout.Widget
            padding="all-but-bottom"
            extraClassName="main-catalog-block__faq"
          >
            <LazyFAQ areas="all" audience="CUSTOMER" hasMicroData />
          </StaticLayout.Widget>
        </>
      )}
    </div>
  );
};

export default MainCatalogBlock;
