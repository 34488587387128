import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import {useInView} from 'react-intersection-observer';
import MainHowTo from '../MainHowTo';
import MainTitle from '../MainTitle';
import MainReassurance from '../MainReassurance';

import './MainInfoBlock.scss';

const MainInfoBlock: FunctionComponent = () => {
  const [ref, inView] = useInView({threshold: 0.1});

  return (
    <div className="main-info-block">
      <div className="main-info-block__wrapper">
        <div
          className={cx(
            'main-info-block__container',
            'main-info-block__container_title',
          )}
        >
          <div
            className={cx('main-info-block__item', 'main-info-block__h1', {
              'main-info-block__item_hidden': inView,
            })}
          >
            <MainTitle />
          </div>
        </div>

        <div ref={ref} className="main-info-block__anchor" />

        <div
          className={cx(
            'main-info-block__container',
            'main-info-block__container_last',
            'main-info-block__container_reassurance',
          )}
        >
          <div
            className={cx(
              'main-info-block__item',
              'main-info-block__reassurance',
              {'main-info-block__item_hidden': !inView},
            )}
          >
            <MainReassurance />
          </div>
        </div>

        <div className="main-info-block__howto">
          <MainHowTo />
        </div>
      </div>
    </div>
  );
};

export default MainInfoBlock;
