import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import CircularProgress from '@pollex/components/circular-progress';

import './MainList.scss';

interface OwnProps {
  isLoading?: boolean;
  heading?: string;
  extraClassName?: string;
}

const MainList: FunctionComponent<OwnProps> = ({
  heading,
  children,
  isLoading,
  extraClassName,
}) => {
  if (isLoading) {
    return (
      <section
        className={cx('main-list', extraClassName)}
        style={{height: '70vh', position: 'relative', display: 'flex'}}
      >
        <CircularProgress color="var(--ruki-grey)" />
      </section>
    );
  }

  return (
    <section className={cx('main-list', extraClassName)}>
      <h3 className="main-list__heading">{heading}</h3>
      {children}
    </section>
  );
};

export default MainList;
