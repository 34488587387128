import React, {FunctionComponent} from 'react';
import {
  useBusinessComponents,
  UnderConstructionNoticeType,
} from 'client/contexts/City';
import {BusinessComponentEnum} from 'client/types/business-component';
import {useAnalytics} from 'client/contexts/Analytics';
import {useLocation} from 'client/contexts/Location';
import {toCategory} from 'client/utils/urls';
import {TileProps} from 'client/ui/Tile';
import MainPageTile from '../MainPageTile';

import './MainNoticeTile.scss';

export interface OwnProps {
  tileProps?: Partial<TileProps>;
  businessComponentName: BusinessComponentEnum;
}

const MainNoticeTile: FunctionComponent<OwnProps> = ({
  tileProps,
  businessComponentName,
}) => {
  const analytics = useAnalytics();
  const location = useLocation();
  const [noticeComponent] = useBusinessComponents<
    UnderConstructionNoticeType[]
  >([businessComponentName]);
  if (!noticeComponent) return null;

  const {
    title,
    caption,
    categories: [
      {
        slug,
        images: [image],
      },
    ],
  } = noticeComponent;

  const extraClassName = tileProps?.extraClassName || '';

  return (
    <MainPageTile
      title={title}
      price={<span>{caption}</span>}
      details=""
      tileProps={
        image && {
          extraClassName: `main-ikea-tile main-page-tile ${extraClassName}`,
          icon: (
            <div
              className="main-ikea-tile__image"
              style={{backgroundImage: `url(${image?.src})`}}
            />
          ),
        }
      }
      categorySlug={slug}
      href={toCategory({slug}).serialize(location)}
      onClickCapture={analytics.handleEvent('ikea_notice_click', {})}
    />
  );
};
export default MainNoticeTile;
