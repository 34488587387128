import React, {FunctionComponent} from 'react';

const CoverGuarantee: FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="401"
    height="218"
    viewBox="0 0 401 218"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m256 88.6667 2.321 12.0003 11.081 1.564-9.43 4.087 2.481 8.773 9.729 3.29-8.041 2.991 2.779 9.97 8.637 3.09-7.247 2.293 2.283 8.275 49.534-4.187H401v-39.28h-81.071L302.011 53l-9.43 5.583 3.921 30.0517-40.502.032Z"
      fill="#000"
    />
    <path
      d="m63.3984 95.6719 45.1286 26.0551L52.9431 218l-11.925-31.455-33.2031 5.4 55.5834-96.2731Z"
      fill="#000"
    />
    <path
      d="M115.855 95.5344 70.7268 121.589l55.5832 96.274 11.925-31.455 33.203 5.4-55.583-96.2736Z"
      fill="#000"
    />
    <path
      d="M80.0115 6.3083c5.7163-3.8187 13.17-3.8187 18.8864 0l.4098.2738a17.0008 17.0008 0 0 0 12.7583 2.5377l.483-.096c6.743-1.3405 13.629 1.5118 17.449 7.2274l.274.4097a16.9987 16.9987 0 0 0 10.815 7.2269l.484.0962c6.742 1.3418 12.013 6.6124 13.354 13.3547l.097.4833a16.9976 16.9976 0 0 0 7.226 10.8157l.41.2738c5.716 3.8199 8.568 10.7062 7.228 17.4488l-.096.4834a16.9984 16.9984 0 0 0 2.537 12.758l.274.4098c3.819 5.7163 3.819 13.17 0 18.8864l-.274.4098a16.9992 16.9992 0 0 0-2.537 12.7583l.096.483c1.34 6.743-1.512 13.629-7.228 17.449l-.41.274a16.9967 16.9967 0 0 0-7.226 10.815l-.097.484c-1.341 6.742-6.612 12.013-13.354 13.354l-.484.097c-4.42.879-8.311 3.479-10.815 7.226l-.274.41c-3.82 5.716-10.706 8.568-17.449 7.228l-.483-.096c-4.421-.879-9.01.033-12.7583 2.537l-.4098.274c-5.7164 3.819-13.1701 3.819-18.8864 0l-.4098-.274a16.9984 16.9984 0 0 0-12.758-2.537l-.4834.096c-6.7426 1.34-13.6289-1.512-17.4488-7.228l-.2738-.41a16.9976 16.9976 0 0 0-10.8157-7.226l-.4833-.097c-6.7423-1.341-12.0129-6.612-13.3547-13.354l-.0962-.484a16.9987 16.9987 0 0 0-7.2269-10.815l-.4097-.274c-5.7156-3.82-8.568-10.706-7.2275-17.449l.0961-.483c.8789-4.421-.034-9.01-2.5377-12.7583l-.2738-.4098c-3.8187-5.7164-3.8187-13.1701 0-18.8864l.2738-.4098a17 17 0 0 0 2.5377-12.758l-.096-.4833c-1.3405-6.7427 1.5118-13.629 7.2274-17.4489l.4097-.2738a16.9996 16.9996 0 0 0 7.2269-10.8157l.0962-.4833c1.3418-6.7423 6.6124-12.0129 13.3547-13.3547l.4833-.0962a16.9996 16.9996 0 0 0 10.8157-7.2269l.2738-.4097c3.8199-5.7156 10.7062-8.568 17.4489-7.2275l.4833.0961a17 17 0 0 0 12.758-2.5377l.4098-.2738Z"
      fill="var(--link-color)"
    />
    <path
      d="M87.0823 123.864 58.1885 76.3717l28.8938 18.9969 37.5127-40.6533-37.5127 69.1487Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.328 89.4547c0 36.9333-29.941 66.8743-66.874 66.8743-36.9335 0-66.8739-29.941-66.8739-66.8743 0-36.9334 29.9404-66.8739 66.8739-66.8739 36.933 0 66.874 29.9405 66.874 66.8739ZM151 88c0 32.585-26.415 59-59 59-32.5848 0-59-26.415-59-59 0-32.5848 26.4152-59 59-59 32.585 0 59 26.4152 59 59Z"
      fill="#F0EFEF"
    />
  </svg>
);

export default CoverGuarantee;
