import React, {FunctionComponent} from 'react';

import cx from 'classnames';
import Link from '@pollex/components/link';
import {useLocation} from 'client/contexts/Location';
import Document from '@pollex/components/icon/components/Document';
import GuaranteeSmall from '@pollex/components/icon/components/GuaranteeSmall';
import {toCompanyAbout} from 'client/utils/urls';
import Nobr from 'client/components/Nobr';
import useMediaQuery from 'client/hooks/useMediaQuery';

import './MainReassurance.scss';

const MainReassurance: FunctionComponent = () => {
  const location = useLocation();
  const isBlackOnWhite = useMediaQuery('(min-width: 900px)');

  const [color, background] = isBlackOnWhite
    ? ['var(--ruki-black)', 'var(--ruki-white)']
    : ['var(--ruki-white)', 'var(--ruki-black)'];

  return (
    <div className="main-reassurance">
      <div className="main-reassurance__icons">
        <Document
          extraClassName={cx(
            'main-reassurance__icon',
            'main-reassurance__shield',
          )}
          height="40px"
          color={color}
          secondaryColor={background}
        />
        <GuaranteeSmall
          extraClassName={cx(
            'main-reassurance__icon',
            'main-reassurance__guarantee',
          )}
          height="40px"
          color={color}
          secondaryColor={background}
        />
      </div>

      <div className="main-reassurance__text">
        Работаем по&nbsp;договору и&nbsp;даём гарантию на&nbsp;12&nbsp;месяцев.
        Если <Nobr>что-то</Nobr>&nbsp;сломается, то&nbsp;исправим
        за&nbsp;свой&nbsp;счёт
        <br />
        <Link
          href={toCompanyAbout().serialize(location)}
          data-shmid="company-about-link"
          className="main-reassurance__link"
        >
          О&nbsp;сервисе
        </Link>
      </div>
    </div>
  );
};

export default MainReassurance;
