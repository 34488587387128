import React, {FunctionComponent} from 'react';
import Anchor from '@pollex/components/anchor';
import Link from '@pollex/components/link';
import {toCompanyAbout} from 'client/utils/urls';
import {useLocation} from 'client/contexts/Location';
import useDidMount from 'client/hooks/useDidMount';
import css from 'common-tags/lib/oneLine';
import useMediaQuery from 'client/hooks/useMediaQuery';
import {useCity} from 'client/contexts/City';
import CitySelect from 'client/components/CitySelect';
import GeoIcon from '@pollex/components/icon/components/Geo';

import './MainTitle.scss';

const MainTitle: FunctionComponent = () => {
  const location = useLocation();
  const mounted = useDidMount();
  const isMobileView = useMediaQuery('(max-width: 600px)');
  const {
    current: {prepositional: cityPrepositional},
  } = useCity();

  const isMobileDevice = isMobileView;

  return (
    <div className="main-title">
      {!isMobileDevice && (
        <Anchor
          themed={false}
          component={Link}
          extraClassName="main-title__slogan"
          href={toCompanyAbout().serialize(location)}
        >
          Руки сделают
        </Anchor>
      )}

      <h1 className="main-title__caption">
        Домашний ремонт по&nbsp;заранее известной цене
      </h1>

      {isMobileDevice && (
        <div className="main-title__city-select-wrapper">
          <CitySelect
            citySelectButtonContent={
              <>
                {cityPrepositional}
                <GeoIcon
                  extraClassName="main-title__city-select-wrapper_icon"
                  color="var(--ruki-cyan)"
                />
              </>
            }
            withCloseButton
          />
        </div>
      )}

      {!mounted && (
        <style>{css`
          .main-title__slogan,
          .main-title__caption {
            font-size: 30px;
          }

          .main-title__slogan {
            text-decoration: none;
          }
        `}</style>
      )}
    </div>
  );
};

export default MainTitle;
