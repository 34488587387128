import React, {FunctionComponent} from 'react';

const CoverCompensation: FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="401"
    height="244"
    viewBox="0 0 401 244"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 62v18.1515h15.2806l3.8201 150.7295H166.813l-3.82-150.7295H177L173.18 62H0Zm31.8325 31.1204 2.5468 123.5016H152.804L147.71 89.2308 31.8325 93.1204Z"
      fill="var(--link-color)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.2031 98.2965 40.7499 210.13H145.167L142.62 97 38.2031 98.2965Zm85.3259 65.4475c5.626 0 10.187-4.643 10.187-10.372 0-5.728-4.561-10.372-10.187-10.372-5.626 0-10.187 4.644-10.187 10.372 0 5.729 4.561 10.372 10.187 10.372Z"
      fill="var(--link-color)"
    />
    <path
      fill="var(--link-color)"
      d="M36.9141 236.055h12.7338v7.7792H36.9141zM138.801 236.055h12.7338v7.7792H138.801z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M401 110.441h-59.808l-19.364-23.7075-8.255-15.2385-6.302 3.8299 2.255 15.1963 4.526 13.9378-13.934-3.327s2.976 8.612 5.351 13.936c2.776 6.222 5.542 9.139 8.082 15.46 2.714 6.757 5.07 17.944 5.07 17.944l15.083.934H401v-38.965Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M250.66 108.078c2.094-.705 4.327-.991 6.521-.75-.145-2.403-.115-4.959.135-7.7101 2.967-.781 29.89-7.2104 31.29-7.5361.072 4.5851.78 8.8012 1.885 12.7712l-26.346 5.437c1.304 1.082 2.498 2.455 3.504 4.198 5.133 8.891-.113 17.701-7.587 20.525 5.783 11.513 11.759 23.756 10.185 42.493 5.74-1.21 33.482-7.751 42.922-10.587 6.886-31.654-10.606-39.042-16.503-65.971-.675-3.083-1.119-6.4378-1.119-6.4378s-.687-6.7498-.735-10.5795c0 0-31.676 7.8381-43.42 10.8173-.914 4.8652-1.087 9.256-.732 13.33Zm56.078 34.664c1.548 5.675 2.101 11.752.809 18.661-7.978 2.202-24.533 6.053-30.72 7.535-.012-7.139-1.087-13.061-2.73-18.271l32.641-7.925Zm-49.529-1.22c-5.681-9.84-21.327-1.135-15.898 8.269 6.44 11.154 21.471 1.383 15.898-8.269Zm6.564-25.056c-6.36-11.017-23.876-1.272-17.797 9.256 7.209 12.489 24.036 1.549 17.797-9.256Z"
      fill="var(--link-color)"
    />
    <path
      d="M159.931 84.6099c0 3.9938 3.237 7.2315 7.231 7.2315 3.994 0 7.232-3.2377 7.232-7.2315s-3.238-7.2315-7.232-7.2315-7.231 3.2377-7.231 7.2315Z"
      fill="#000"
    />
    <path
      d="m210.272 91.5784 10.855-.9893-9.832-4.7045-42.746-20.4533 15.839-24.2852 5.136-7.8759-8.748 3.4457-25.37 9.992-3.099-42.924-5.824-.7743-16.533 47.2701-23.701-30.3445-6.9733-8.9276 1.6403 11.2087 5.822 39.7822-23.2492 13.5574-9.5889 5.5916h29.7271l-2.733 34.3585-.534 6.705 5.346-4.084 27.949-21.3517 28.352 29.4047 5.16 5.352V95.5071l43.105-3.9287Z"
      fill="#000"
      stroke="#F0EFEF"
      strokeWidth="6"
    />
  </svg>
);

export default CoverCompensation;
