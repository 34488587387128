import React, {FunctionComponent} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import {useQuery} from '@apollo/client';
import handsRuGetDataForMainPage, {
  HandsRuGetDataForMainPageData,
  HandsRuGetDataForMainPageVariables,
} from 'query-wrappers/HandsRuGetDataForMainPage';
import CircularProgress from '@pollex/components/circular-progress';
import PrettyDate from '@pollex/components/pretty-date';
import Carousel from '@pollex/components/carousel';
import StaticLayout from 'client/components/Layouts/StaticLayout';
import useAnalyticsEventOnView from 'client/hooks/useAnalyticEventOnView';
import ReviewNPS from 'client/pages/PageClientOrders/components/ReviewNPS';
import ExternalReviewIcon from '../ExternalReviewIcon';

import './ExternalReviews.scss';

const ExternalReviews: FunctionComponent = () => {
  const {data, loading} = useQuery<
    HandsRuGetDataForMainPageData,
    HandsRuGetDataForMainPageVariables
  >(handsRuGetDataForMainPage, {variables: {bannerKind: 'WEB'}});
  const ref = useAnalyticsEventOnView('external_reviews_in_view', {});
  const [carouselRef, carouselAPI] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    skipSnaps: false,
    inViewThreshold: 0.6,
  });

  if (loading) {
    return (
      <StaticLayout.Widget extraClassName="external-reviews">
        <StaticLayout.Block position="relative">
          <CircularProgress color="var(--ruki-grey)" />
        </StaticLayout.Block>
      </StaticLayout.Widget>
    );
  }

  if (!data?.handsCommonInfo.externalReviews?.length) {
    return null;
  }

  return (
    <StaticLayout.Widget
      innerRef={ref}
      padding="bottom"
      extraClassName="external-reviews"
    >
      <StaticLayout.Block>
        <StaticLayout.Title extraClassName="external-reviews__title" level={2}>
          Отзывы
        </StaticLayout.Title>
      </StaticLayout.Block>

      <Carousel
        carouselRef={carouselRef}
        carouselAPI={carouselAPI}
        bullets={() => null}
        leftNav={() => null}
        rightNav={() => null}
      >
        {data?.handsCommonInfo.externalReviews.map(r => (
          <div key={r.id} className="external-reviews__item">
            <div>
              <p className="external-reviews__heading">
                <span>{r.author}</span> <PrettyDate>{r.createdAt}</PrettyDate>
              </p>
              <p className="external-reviews__text">{r.text}</p>
              <div className="external-reviews__nps-container">
                {r.mark} из 5
                <ReviewNPS
                  nps={r.mark * 2}
                  color="var(--ruki-yellow)"
                  extraClassName="external-reviews__nps"
                  size="small"
                />
              </div>
            </div>
            <p className="external-reviews__source">
              <span className="external-reviews__source-label">Источник: </span>
              <ExternalReviewIcon source={r.source} variant="FULL" />
            </p>
          </div>
        )) || []}
      </Carousel>
    </StaticLayout.Widget>
  );
};

export default ExternalReviews;
