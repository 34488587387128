import {useEffect} from 'react';
import {useAnalytics} from 'client/contexts/Analytics';
import {useLocation} from 'client/contexts/Location';
import {
  EventKey,
  EventValue,
} from 'client/contexts/Analytics/trackers/TrackerAPI';

export default function useAnalyticEvent(
  name: EventKey,
  params: EventValue,
): void {
  const {handleEvent} = useAnalytics();
  const {location} = useLocation();
  const fn = handleEvent(name, params);

  useEffect(() => {
    fn();
  }, [location.pathname, location.search]);
}
