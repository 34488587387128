import React, {FunctionComponent} from 'react';

import {Helmet} from 'react-helmet';
import useSiteTitle from 'client/hooks/useSiteTitle';

interface OwnProps {
  children: string;
  raw?: boolean;
}

const PageTitle: FunctionComponent<OwnProps> = ({children, raw}) => {
  const pageTitle = useSiteTitle(children);

  return (
    <Helmet>
      <title>{raw ? children : pageTitle}</title>
    </Helmet>
  );
};

export default PageTitle;
