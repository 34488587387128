import React, {FunctionComponent} from 'react';
import HowWeWork from 'client/components/HowWeWork';
import StaticLayout from 'client/components/Layouts/StaticLayout';

import './MainHowWeWork.scss';

const MainHowWeWork: FunctionComponent = () => {
  return (
    <StaticLayout.Widget extraClassName="main-how-we-work" padding="horizontal">
      <StaticLayout.Block>
        <StaticLayout.Title extraClassName="main-how-we-work__title" level={2}>
          Как мы&nbsp;работаем
        </StaticLayout.Title>
      </StaticLayout.Block>
      <StaticLayout.Block padding="none">
        <HowWeWork />
      </StaticLayout.Block>
    </StaticLayout.Widget>
  );
};

export default MainHowWeWork;
