import React, {FunctionComponent} from 'react';
import Link from '@pollex/components/link';
import {HandsRuGetDataForMainPageData} from 'query-wrappers/HandsRuGetDataForMainPage';
import {useAnalytics} from 'client/contexts/Analytics';
import Arrow from '@pollex/components/icon/components/ArrowTailed2';

import './Banner.scss';

const Banner: FunctionComponent<
  HandsRuGetDataForMainPageData['handsCommonInfo']['banner']
> = ({
  title,
  description,
  actionCaption,
  actionUrl,
  images,
  colors: {title: titleColor, background: backgroundColor},
}) => {
  const {handleEvent} = useAnalytics();

  return (
    <Link
      href={actionUrl}
      external
      target="_blank"
      onClickCapture={handleEvent('main_banner_click', {
        url: actionUrl,
      })}
    >
      <div
        style={{
          backgroundColor,
        }}
        className="main-page-banner"
      >
        <Arrow
          extraClassName="main-page-banner__arrow"
          width="20px"
          height="20px"
          color="var(--ruki-black)"
        />

        <img
          className="main-page-banner__image"
          src={images[0]?.file}
          alt={title}
          style={{
            right: '0',
          }}
        />

        <div className="main-page-banner__content">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
            }}
          >
            <span
              className="main-page-banner__title"
              style={{
                color: titleColor,
              }}
            >
              {title}
            </span>

            <span
              className="main-page-banner__description"
              style={{
                color: titleColor,
              }}
            >
              {description}
            </span>
          </div>

          <span
            className="main-page-banner__action"
            style={{
              color: titleColor,
            }}
          >
            {actionCaption}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default Banner;
