import React, {FunctionComponent} from 'react';
import {Helmet} from 'react-helmet';
import {useCity} from 'client/contexts/City';
import PlainLayout from 'client/components/Layouts/PlainLayout';
import CanonicalLink from 'client/components/CanonicalLink';
import useAnalyticEvent from 'client/hooks/useAnalyticEvent';
import ApplicationStructuredData from 'client/components/StructuredData/Application';
import PageTitle from 'client/components/PageTitle';
import {toMain} from 'client/utils/urls';
import css from 'common-tags/lib/oneLine';
import useDidMount from 'client/hooks/useDidMount';
import MainInfoBlock from './components/MainInfoBlock';
import MainCatalogBlock from './components/MainCatalogBlock';
import MainFooter from './components/MainFooter';

import './PageMain.scss';

const PageMain: FunctionComponent = () => {
  useAnalyticEvent('quick_view', {});
  const mounted = useDidMount();
  const {
    current: {prepositional},
  } = useCity();

  return (
    <PlainLayout withFooter={false}>
      <CanonicalLink to={toMain()} />
      <PageTitle raw>
        {`Руки — сервис домашнего ремонта ${prepositional}`}
      </PageTitle>
      <Helmet>
        <meta
          name="description"
          content="Руки — это cервис домашнего ремонта. &#9989; Опишите задачу, познакомьтесь с мастером и вот: ремонт сделан по заранее известной цене, с гарантией и договором"
        />
      </Helmet>
      {!mounted && (
        <style>
          {css`
            body {
              background: #000;
              color: #fff;
            }
          `}
        </style>
      )}
      <ApplicationStructuredData />

      <div className="page-main">
        <MainInfoBlock />
        <MainCatalogBlock />
        <MainFooter />
      </div>
    </PlainLayout>
  );
};

export default PageMain;
