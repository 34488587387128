import React, {FunctionComponent} from 'react';

const CoverSpecialists: FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="429"
    height="389"
    viewBox="0 0 429 389"
    fill="none"
  >
    <path
      d="m4.213 179.052 29.6617-29.662 10.469 4.362-10.469 27.917 40.1309 19.193-30.0982 15.703 1.3086 34.896.4362 19.193 2.1811 75.9H17.299v-75.9l.8724-19.193.8724-35.768-14.8309-36.641Zm54.9616 9.596 6.9794-16.576 11.3411 13.086-5.2344 9.597-13.0861-6.107Z"
      fill="var(--link-color)"
    />
    <path
      d="M57.4081 251.586H45.2306l.4349 19.293H17.3969l.8693-19.293H6.0887L0 280.525l10.3609 4.129-9.491 2.886 2.1745 9.646 9.1993 3.661-7.8946 1.601 2.3454 11.094 7.8283 3.098-6.6944 1.593 2.61 11.4h46.1004L105 388.998h48l-91.2423-109.35-4.3496-28.062Z"
      fill="#000"
    />
    <path
      d="M271.967 145.161h12.104l-.111-8.879-11.993-.111-5.969 4.495 5.969 4.495Zm33.632-16.094-17.219 4.548v15.042l17.219 6.017v-25.607Zm25.077 50.169h22.064l6.542 41.177-8.427 7.992 2.439 5.882 38.141.111-12.972-62.599 15.855-11.654-1.552-29.968-16.077-10.1-65.928 6.017v33.093l19.804 4.177.111 15.872Z"
      fill="var(--link-color)"
    />
    <path
      d="m381.263 169.905 10.044-7.832 6.584 25.652H429v39.674h-79.744l-1.73-9.801 8.026-2.72-8.987-2.673-1.874-10.599 7.353-2.86-8.411-3.283-1.73-9.754 8.122-3.564h-23.836l-3.028-12.24h58.102Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.007 163.664c24.391 17.102-3.495-10.784 3.977-3.313 4.602 4.603 8.53 10.015 10.84 16.51 1.057 2.946 2.047 5.692 2.971 8.37 6.339 17.677 10.167 28.658 25.195 40.447l4.186-4.452 6.28 4.66 3.277 1.927 4.474 3.664 3.675 2.596 7.814 6.194 22.942.941 11.682 10.332-40.83 40.83-9.265-10.615-2.413-23.604-6.666-7.476-2.799-3.204-4.4-4.94-2.602-2.601-4.064-5.009 2.903-3.036c-16.296-13.057-20.72-25.31-27.256-43.723-.991-2.611-1.914-5.424-2.972-8.236-5.217-14.261 5.492-.042-6.211-6.751 2.94 2.13-3.812-2.192 0 0l-.738-9.511c9.695 5.915-3.677-2.193 0 0Zm122.712 116.638-6.613 6.614-20.154-18.939 6.952-6.951 19.815 19.276Zm-19.908 19.909-6.614 6.614-20.153-18.939 6.951-6.951 19.816 19.276Z"
      fill="var(--link-color)"
    />
    <path
      d="m233 240-39.852-28.566C147.233 178.521 120 125.493 120 69"
      stroke="var(--link-color)"
      strokeWidth="8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m219.714 68.843-3.509 150.092 38.032.102 2.237 21.065-9.718 1.604-2.078-10.958-27.049 4.583 6.305 28.311 21.761-3.462 1.424-13.952h8.299l1.056 23.728-41.794 7.434-7.322-15.479.306 21.182 18.304 14.665-5.492 7.027-23.693-17.618-3.559-18.942v25.052l14.033 17.007-7.932 4.684-15.762-18.229-1.424-20.571-10.684 41.245h-10.67l16.168-74.546 2.387-174.024h40.374Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M226.514 238.462c-.924-2.679 1.057 2.946 0 0l13.693-6.985 3.675 2.596 7.814 6.194 22.942.941 11.682 10.332-40.83 40.83-9.265-10.615-2.413-23.604-6.666-7.476-2.799-3.204-4.4-4.94-1.693-5.337 8.26 1.268Zm75.205 41.84-6.613 6.614-20.154-18.939 6.952-6.951 19.815 19.276Zm-19.908 19.909-6.614 6.614-20.153-18.939 6.951-6.951 19.816 19.276Z"
      fill="var(--link-color)"
    />
  </svg>
);

export default CoverSpecialists;
