import React, {FunctionComponent, useState} from 'react';
import cx from 'classnames';
import StaticLayout from 'client/components/Layouts/StaticLayout';

import plural from '@pollex/utils/plural';
import Button from '@pollex/components/button';
import Modal from '@pollex/components/modal';
import useAnalyticsEventOnView from 'client/hooks/useAnalyticEventOnView';
import useEmblaCarousel from 'embla-carousel-react';
import Carousel from '@pollex/components/carousel';
import Elaboration, {ElaborationTopicType} from 'client/components/Elaboration';
import NB from 'client/components/NB';
import useMediaQuery from 'client/hooks/useMediaQuery';
import {useAnalytics} from 'client/contexts/Analytics';
import CoverSpecialists from './img/cover_specialists';
import CoverPrice from './img/cover_price';
import CoverGuarantee from './img/cover_guarantee';
import CoverCompensation from './img/cover_compensation';
import CoverSupport from './img/cover_support';

import './WhyChooseUs.scss';

const WhyChooseUs: FunctionComponent<OwnProps> = ({
  specialistCount,
  isMerged,
  isKitchen,
}) => {
  const ref = useAnalyticsEventOnView('why_choose_us_in_view', {});
  const isDesktop = useMediaQuery('(min-width: 901px)');
  const {handleEvent} = useAnalytics();
  const [
    shouldShowSpecialistModal,
    setShouldShowSpecialistModal,
  ] = useState<ElaborationTopicType | null>(null);
  const [carouselRef, carouselAPI] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    skipSnaps: false,
    inViewThreshold: 0.6,
  });

  const specialistSlideHeading =
    specialistCount > 10
      ? `${specialistCount} ${plural(
          specialistCount,
          'мастер',
          'мастера',
          'мастеров',
        )}`
      : 'Отобранные мастера';

  const slides = [
    <WhyChooseUsSlide
      name="specialists"
      key="coverSpecialists"
      cover={CoverSpecialists}
      heading={specialistSlideHeading}
      text={
        <>
          Работу выполнит профессионал. Мастера проходят отбор и&nbsp;сдают
          экзамен
        </>
      }
      onLinkClick={setShouldShowSpecialistModal}
      topic="specialist"
    />,
    <WhyChooseUsSlide
      name="price"
      key="coverPrice"
      cover={CoverPrice}
      heading="Фиксированная цена"
      text={
        <>
          Назовём стоимость до&nbsp;начала работ. Цену зафиксируете
          в&nbsp;договоре
        </>
      }
      onLinkClick={setShouldShowSpecialistModal}
      topic="price"
    />,
    <WhyChooseUsSlide
      name="guarantee"
      key="coverGuarantee"
      cover={CoverGuarantee}
      heading="Гарантия 12&nbsp;месяцев"
      onLinkClick={setShouldShowSpecialistModal}
      text={
        <>
          Исправим за&nbsp;свой счёт, если что-то из&nbsp;сделанного мастером
          сломается
        </>
      }
      topic="guarantee"
    />,
    <WhyChooseUsSlide
      name="compensation"
      key="coverCompensation"
      cover={CoverCompensation}
      heading="Компенсация до&nbsp;100&nbsp;000&nbsp;₽"
      onLinkClick={setShouldShowSpecialistModal}
      text={
        <>
          Компенсируем ущерб, если мастер&nbsp;что-то испортит в&nbsp;процессе
        </>
      }
      topic="compensation"
    />,
    <WhyChooseUsSlide
      name="support"
      key="coverSupport"
      cover={CoverSupport}
      heading="Поддержка"
      text={
        <>
          Мы&nbsp;на&nbsp;связи по&nbsp;телефону и&nbsp;на&nbsp;сайте. Уточним
          детали и&nbsp;ответим на&nbsp;все вопросы по&nbsp;заказу
          до&nbsp;приезда мастера
        </>
      }
    />,
  ];

  return (
    <NB>
      <section className="why-choose-us">
        <StaticLayout.Widget
          extraClassName="why-choose-us__title"
          padding="none"
          innerRef={ref}
        >
          <StaticLayout.Block>
            <StaticLayout.Title
              level={2}
              extraClassName={cx({
                'why-choose-us__title_main': !isMerged,
              })}
            >
              Почему Руки
            </StaticLayout.Title>
          </StaticLayout.Block>
        </StaticLayout.Widget>

        <div className="why-choose-us__inner">
          {isDesktop ? (
            slides
          ) : (
            <Carousel
              carouselRef={carouselRef}
              carouselAPI={carouselAPI}
              bullets={props => (
                <Carousel.Bullets
                  onClickCapture={handleEvent('carousel_nav_click', {
                    components: 'why_choose_us',
                  })}
                  {...(props as any)}
                  extraClassName={cx('why-choose-us__bullets', {
                    'why-choose-us__bullets_colored': isMerged,
                  })}
                />
              )}
              leftNav={() => null}
              rightNav={() => null}
            >
              {slides}
            </Carousel>
          )}
        </div>

        {shouldShowSpecialistModal && (
          <Modal
            size="large"
            onClose={() => setShouldShowSpecialistModal(null)}
          >
            <Elaboration
              isKitchen={!!isKitchen}
              topic={shouldShowSpecialistModal}
            />
          </Modal>
        )}
      </section>
    </NB>
  );
};

interface WhyChooseUsSlideProps {
  name: string;
  cover: FunctionComponent;
  heading: string;
  text: JSX.Element;
  onLinkClick?: (topic: ElaborationTopicType) => void;
  topic?: ElaborationTopicType;
}

const WhyChooseUsSlide: FunctionComponent<WhyChooseUsSlideProps> = ({
  name,
  cover: Cover,
  heading,
  text,
  onLinkClick,
  topic,
}) => {
  const {handleEvent} = useAnalytics();
  return (
    <article className="why-choose-us__item">
      <div
        className={cx('why-choose-us__cover', `why-choose-us__cover_${name}`)}
      >
        <Cover />
      </div>
      <div className="why-choose-us__info">
        <StaticLayout.Title level={3} extraClassName="why-choose-us__heading">
          {heading}
        </StaticLayout.Title>
        <StaticLayout.Text extraClassName="why-choose-us__text">
          {text}
        </StaticLayout.Text>
        {!!onLinkClick && topic && (
          <Button
            design="plain"
            extraClassName="why-choose-us__more"
            onClick={() => {
              handleEvent(`why_choose_us_section_click_${topic}`, {})();
              onLinkClick(topic);
            }}
          >
            {topic === 'guarantee' && 'О гарантийных случаях →'}
            {topic === 'specialist' && 'Подробнее о мастерах →'}
            {topic === 'price' && 'Как устанавливаем цену →'}
            {topic === 'compensation' && 'Как исправляем ошибки →'}
          </Button>
        )}
      </div>
    </article>
  );
};

interface OwnProps {
  specialistCount: number;
  isMerged?: boolean;
  isKitchen?: boolean;
}

export default WhyChooseUs;
