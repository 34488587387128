import React, {FunctionComponent, useEffect, useState} from 'react';
import Link from '@pollex/components/link';
import {useAnalytics} from 'client/contexts/Analytics';
import {useLocation} from 'client/contexts/Location';
import ArrowTailed from '@pollex/components/icon/components/ArrowTailed2';
import typographize from '@pollex/utils/typographize';
import useLocalStorage from '@pollex/hooks/use-local-storage';
import Search from 'client/components/Search';
import {toService} from 'client/utils/urls';
import {ServiceType} from 'client/types/service';
import {useTheme} from 'client/contexts/Theme';

import './MainSearchBlock.scss';

const MainSearchBlock: FunctionComponent = () => {
  const [lastVisitedService, setLastVisitedService] = useState<
    ServiceType | null | undefined
  >();
  const [lastVisitedServiceJSON] = useLocalStorage<string>(
    'last-visited-service',
  );
  const location = useLocation();
  const {handleEvent} = useAnalytics();
  const {cache} = useTheme();
  const [theme, setTheme] = useState({
    background: 'var(--placeholder-shimmer)',
    text: 'var(--ruki-white)',
  });

  useEffect(() => {
    setLastVisitedService(
      lastVisitedServiceJSON ? JSON.parse(lastVisitedServiceJSON) : null,
    );
  }, []);

  useEffect(() => {
    const themeFromCache = cache.get(
      lastVisitedService?.defaultCategory?.slug || '',
    );

    if (!themeFromCache) {
      return;
    }

    setTheme({
      background: themeFromCache.accentBackground,
      text: themeFromCache.accentText,
    });
  }, [cache, setTheme]);

  return (
    <>
      <Search keepInput withLastQueries type="MAIN" />

      {lastVisitedService?.name && (
        <Link
          href={toService(lastVisitedService, {
            category: lastVisitedService?.defaultCategory,
          }).serialize(location)}
          className="main-search-block__continue"
          onClickCapture={handleEvent('continue_last_order_click', {})}
          // @ts-ignore
          style={{
            background: theme.background,
          }}
        >
          <div className="main-search-block__continue_left">
            <p
              className="main-search-block__continue-notation"
              style={{color: theme.text}}
            >
              Продолжить оформление заказа
            </p>

            <p
              className="main-search-block__continue-service-name"
              style={{color: theme.text}}
            >
              {typographize(lastVisitedService?.name)}
            </p>
          </div>

          <div className="main-search-block__continue_rigth">
            <ArrowTailed
              extraClassName="main-search-block__continue-icon"
              color={theme.text}
              width="20"
              height="18"
            />
          </div>
        </Link>
      )}
    </>
  );
};

export default MainSearchBlock;
