import React, {FunctionComponent} from 'react';

import {Helmet} from 'react-helmet';
import {useLocation} from 'client/contexts/Location';
import SerializableURL from 'client/utils/serializable-url';

interface OwnProps {
  to: SerializableURL;
}

const CanonicalLink: FunctionComponent<OwnProps> = ({to}: OwnProps) => {
  const location = useLocation();
  return (
    <Helmet>
      <link
        rel="canonical"
        href={to.serialize(location, {isCanonical: true})}
      />
    </Helmet>
  );
};

export default CanonicalLink;
