import React, {FunctionComponent} from 'react';
import {ExternalReviewSource} from 'client/types/external-review';

import AppStoreIcon from './components/AppStoreIcon';
import GooglePlayIcon from './components/GooglePlayIcon';
import TwoGisIcon from './components/TwoGisIcon';
import YandexMapsIcon from './components/YandexMapsIcon';

import YandexLogoIcon from './img/yandex.svg';
import TBankLogoIcon from './img/tbank.svg';
import TwoGisLogoIcon from './img/two-gis.svg';
import GoogleLogoIcon from './img/google.svg';

interface OwnProps {
  source: ExternalReviewSource;
  variant: 'LOGO' | 'FULL';
}

const ExternalReviewIcon: FunctionComponent<OwnProps> = ({source, variant}) => {
  if (variant === 'LOGO') {
    switch (source) {
      case 'GOOGLE_PLAY':
        return <img src={GoogleLogoIcon} alt="Google" />;

      case 'YANDEX_MAPS':
        return <img src={YandexLogoIcon} alt="Яндекс" />;

      case 'TBANK':
        return <img src={TBankLogoIcon} alt="Т-Банк" />;

      case 'TWO_GIS':
        return <img src={TwoGisLogoIcon} alt="2GIS" />;

      case 'APP_STORE':
        return <AppStoreIcon />;
    }
  }

  switch (source) {
    case 'GOOGLE_PLAY':
      return <GooglePlayIcon color="#fff" />;

    case 'YANDEX_MAPS':
      return <YandexMapsIcon color="#fff" />;

    case 'TBANK':
      return <TBankLogoIcon />;

    case 'TWO_GIS':
      return <TwoGisIcon color="#fff" />;

    case 'APP_STORE':
      return <AppStoreIcon color="#fff" />;
  }
};

export default ExternalReviewIcon;
