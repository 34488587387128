import React, {FunctionComponent} from 'react';
import Footer from 'client/components/Footer';
import cx from 'classnames';
import MainReassurance from '../MainReassurance';
import MainHowTo from '../MainHowTo';

import './MainFooter.scss';

const MainFooter: FunctionComponent = () => {
  return (
    <div className="main-footer">
      <div className="main-footer__blocks">
        <div
          className={cx('main-footer__block', 'main-footer__block_reassurance')}
        >
          <MainReassurance />
        </div>
        <div className={cx('main-footer__block', 'main-footer__block_howto')}>
          <MainHowTo />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MainFooter;
